<template>
  <form class="app-contact-form" @submit.prevent="send()">
    <div class="app-contact-form__item app-contact-form__item--name">
      <input
        v-model="form.name"
        :placeholder="$t('contact-form.name.placeholder')"
        type="text"
        class="qv-input"
        required
      >
    </div>

    <div class="app-contact-form__item app-contact-form__item--email">
      <input
        v-model="form.email"
        :placeholder="$t('contact-form.email.placeholder')"
        type="email"
        class="qv-input"
        required
      >
    </div>

    <!-- <div
      v-if="subjectOptions.length"
      class="app-contact-form__item app-contact-form__item--subject"
    >
      <select
        v-model="form.subject"
        class="qv-select"
        required
      >
        <option disabled value="">{{ $t('contact-form.subject.placeholder') }}</option>
        <option
          v-for="(option, index) in subjectOptions"
          :key="index"
        >{{ option }}</option>
      </select>
    </div> -->

    <div class="app-contact-form__item app-contact-form__item--message">
      <textarea
        v-model="form.message"
        :placeholder="$t('contact-form.message.placeholder')"
        class="qv-textarea"
        rows="5"
        required
      ></textarea>
    </div>

    <div class="app-contact-form__item app-contact-form__item--privacy-policy">
      <div class="qv-check">
        <input
          v-model="form.agree"
          class=""
          type="checkbox"
          required
        >
        <label>
          <i18n path="contact-form.privacy-policy.label.text">
            <template #privacy-policy>
              <a href="#"> {{ $t('contact-form.privacy-policy.label.privacy-policy') }}</a>
            </template>
          </i18n>
        </label>
      </div>
    </div>

    <div class="app-contact-form__item app-contact-form__item--button">
      <button
        class="btn"
        type: submit
      >{{ $t('contact-form.button') }}</button>
    </div>
  </form>
</template>

<script>
import apiService from '@/api';

export default {
  data() {
    return {
      form: {
        name: '',
        email: '',
        // subject: '',
        message: '',
        agree: false,
      },
    };
  },
  computed: {
    valid() {
      return this.form.email
      // && this.form.subject
      && this.form.name
      && this.form.message
      && this.form.agree;
    },
    subjectOptions() {
      return this.$t('contact-form.subject.options');
    },
  },
  methods: {
    send() {
      const {
        name,
        email,
        // subject,
        message,
        agree,
      } = this.form;

      if (agree) {
        apiService.contact({
          name,
          email,
          // subject,
          message,
        }).then(() => {
          this.form.name = '';
          this.form.email = '';
          // this.form.subject = '';
          this.form.message = '';
          this.form.agree = false;
          this.$emit('sent');
        }).catch((err) => {
          this.$emit('error', err);
        });
      }
    },
  },
};
</script>

<style lang="scss">
.app-contact-form {
  &__item {
    width: 100%;
    margin-bottom: 16px;

    .qv-check {
      label {
        a {
          text-decoration: none;
          white-space: nowrap;
        }
      }
    }

    &--button {
      .btn {
        width: 100%;
      }
    }
  }

  @include media-bp(mob) {
    &__item {
      margin-bottom: 8px;
    }
  }

}
</style>
